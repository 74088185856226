<script setup lang="ts">
import debounce from 'debounce';

// icons
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiCssSliderProps {
  arrowDisplayClasses?: string;
  inactiveWrapperClasses?: string;
  mq?: 'default' | 'sm' | 'md' | 'lg';
}
const props = withDefaults(defineProps<CiCssSliderProps>(), {
  arrowDisplayClasses: 'hidden lg:flex',
  inactiveWrapperClasses: '',
  mq: 'default',
});

/********************
 * COMPOSITIONS      *
 ********************/
const viewport = useViewport();

/********************
 * REFS & VARS       *
 ********************/
const _sliderActiveByDefault = props.mq === 'default';
const _defaultSliderClasses = 'slider left-0 top-0 flex size-full flex-row flex-nowrap items-stretch gap-x-[15px] overflow-y-hidden overflow-x-scroll whitespace-nowrap pb-[0.7rem]';
const _defaultSliderStyles = '-ms-overflow-style: none; -webkit-overflow-scrolling: touch; scroll-snap-type: x mandatory; scrollbar-width: none;';
const canSlideLeft = ref(false);
const canSlideRight = ref(false);
const clientWidth = ref(0);
const resizeObserver = ref<ResizeObserver | null>(null);
const scrollWidth = ref(0);
const sliderRef = ref<HTMLElement | null>(null);
const sliderActive = ref(_sliderActiveByDefault);
const sliderCssClasses = ref(_sliderActiveByDefault ? _defaultSliderClasses : props.inactiveWrapperClasses);
const sliderStyles = ref(_sliderActiveByDefault ? _defaultSliderStyles : '');

/********************
 * HOOKS             *
 ********************/
onMounted(() => {
  if (sliderRef.value) {
    if ('ResizeObserver' in window) {
      resizeObserver.value = new ResizeObserver(debounce(handleScrollAndResize, 150));
      resizeObserver.value.observe(sliderRef.value);
    }
    sliderRef.value.onscroll = debounce(handleScroll, 150);
  }
});

onBeforeUnmount(() => {
  resizeObserver.value?.disconnect();
  if (sliderRef.value) {
    sliderRef.value.onscroll = null;
  }
});

/********************
 * FUNCTIONS         *
 ********************/
function handleScrollAndResize() {
  handleResize();
  handleScroll();
}

function handleResize() {
  clientWidth.value = sliderRef.value?.getBoundingClientRect().width || 0;
  scrollWidth.value = sliderRef.value?.scrollWidth || 0;

  if (props.mq !== 'default') {
    sliderActive.value = viewport.isLessThan(props.mq);
    sliderCssClasses.value = sliderActive.value ? _defaultSliderClasses : props.inactiveWrapperClasses;
    sliderStyles.value = sliderActive.value ? _defaultSliderStyles : '';
  }
}

function handleScroll() {
  if (clientWidth.value >= scrollWidth.value) {
    canSlideLeft.value = false;
    canSlideRight.value = false;
    return;
  }

  const scrollLeft = sliderRef.value?.scrollLeft || 0;
  canSlideLeft.value = scrollLeft > 0;
  canSlideRight.value = clientWidth.value + Math.round(scrollLeft) !== scrollWidth.value;
}

function onLeftClick() {
  sliderRef.value?.scrollBy({ left: -sliderRef.value?.clientWidth, behavior: 'smooth' });
}

function onRightClick() {
  sliderRef.value?.scrollBy({ left: sliderRef.value?.clientWidth, behavior: 'smooth' });
}
</script>

<template>
  <section class="relative">
    <div ref="sliderRef" :class="sliderCssClasses" :style="sliderStyles">
      <slot />
    </div>
    <div
      v-if="canSlideLeft"
      :class="`absolute left-0 top-0 z-10 hidden h-full -translate-x-full items-center lg:flex ${props.arrowDisplayClasses}`"
      @click.prevent.stop="onLeftClick"
    >
      <CiAwesomeIcon
        :icon="faChevronLeft"
        class="hover:cursor-pointer"
        ratio="1.0"
      />
    </div>
    <div
      v-if="canSlideRight"
      :class="`absolute right-0 top-0 z-10 h-full translate-x-full items-center ${arrowDisplayClasses}`"
      @click.prevent.stop="onRightClick"
    >
      <CiAwesomeIcon
        :icon="faChevronRight"
        class="hover:cursor-pointer"
        ratio="1.0"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.slider {
  &::-webkit-scrollbar {
    display: none;
  }

  :deep(.slide) {
    @apply h-auto whitespace-normal;
  }
}
</style>
